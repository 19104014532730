import copy from 'copy-to-clipboard'
import $ from 'jquery'

$(document).on('click', '[data-clipboard]', e => {
  e.preventDefault();
  if ($(e.currentTarget).data('stopPropagation') === true) {
    e.stopPropagation();
  }

  let $a = $(e.currentTarget);
  let text = $a.data('clipboard');
  if (copy(text)) {
    $a.tooltip({
      title: $a.data('success'),
      placement: $a.data('placement') ? $a.data('placement') : 'top',
      customClass: $a.data('class') ? $a.data('class') : 'tooltip--clipboard',
      trigger: 'manual'
    }).tooltip('show');
    setTimeout(() => $a.tooltip('dispose'), Number($a.data('dispose')) || 500);
  } else {
    console.log('Copy functionality is not available!');
  }
});
