document.addEventListener('turbolinks:load', function () {
  jQuery(".draggable").sortable({
    delay: 150,
    stop: function () {
      var selectedData = new Array();
      jQuery('.draggable > tr').each(function () {
        selectedData.push(jQuery(this).attr("id"));
      });
      updateOrder(selectedData, jQuery(this).data('url'));
    }
  });

  function updateOrder(data, url) {
    $.ajax({
      url: url,
      type: 'post',
      data: {
        article_ids: data
      },
      success: function () {
        alert('your change successfully saved');
      }
    })
  }
});