import consumer from './consumer'
import $ from 'jquery'
import setupInlinePlugins from 'services/inline-plugins'

consumer.subscriptions.create({ channel: "MeetingResultsChannel", survey: window.location.href.split('/').at(4) }, {
  received(data) {
    $('.participants-section .count').replaceWith('<span class="count">'+ data.secondary_nav_footer +'</span>');
    $('.participants-section').addClass('animate-flash');
    $('.results-positive').replaceWith(data.positive_responses);
    $('.results-negative').replaceWith(data.negative_responses);
    $('.results-new-positive').replaceWith(data.positive_responses);
    $('.results-new-negative').replaceWith(data.negative_responses);
    setupInlinePlugins($('body'));
  }
});
