import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['response', 'submitBtn', 'trackerName', 'surveyType', 'notApplicable'];

  enableSubmit() {
    let trackerNameOldValue = this.trackerNameTarget.dataset.oldvalue;
    let trackerNameNewValue = this.trackerNameTarget.value;

    let surveyTypeOldValue = this.surveyTypeTarget.dataset.oldvalue;
    let surveyTypeNewValue = this.surveyTypeTarget.value;

    if ((trackerNameOldValue !== trackerNameNewValue) || (surveyTypeOldValue !== surveyTypeNewValue)) {
      this.submitBtnTarget.classList.remove('disabled');
    } else {
      this.submitBtnTarget.classList.add('disabled');
    }
  }

  checkForm() {
    let stats = this.responseTargets.reduce((acc, node) => {
      if (!acc.hasOwnProperty(node.name)) {
        acc[node.name] = false;
      }

      if (node.checked) {
        acc[node.name] = true
      }
      return acc;
    }, {});

    let notApplicableStats = this.notApplicableTargets.reduce((acc, node) => {
      if (!node.classList.contains('checked')) {
        acc[`not_applicable[${node.getAttribute('data-conduct-id')}]`] = true;
      } else {
        acc[`not_applicable[${node.getAttribute('data-conduct-id')}]`] = false
      }
      return acc;
    }, {});

    if (Object.values(stats).includes(false)) {
      this.submitBtnTarget.classList.add('disabled');
    } else if (!Object.values(stats).includes(false) && !Object.values(notApplicableStats).includes(false)) {
      this.submitBtnTarget.classList.remove('disabled');
    } else {
      this.submitBtnTarget.classList.remove('disabled');
    }
  }
}