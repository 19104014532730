import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["status"]

  makeRequest() {
    let data = {
      "task[status]": this.statusTarget.value
    }

    Rails.ajax({
      url: this.statusTarget.dataset.url,
      type: "PUT",
      data: new URLSearchParams(data).toString(),
      dataType: "json"
    });
  }
}
