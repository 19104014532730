import $ from 'jquery'

const passwordToggler = node => {
  let $node = $(node);
  let $toggler = $(node).next();
  $toggler.on('click', () => {
    let newType = $node.attr('type') === 'password' ? 'text' : 'password';
    $node.attr('type', newType);
  });
}

const passwordValidator = node => {
  let $node = $(node);
  let $validator = $node.parents('.password-container').find('.tooltip');

  $node.on('focusin', () => {
    $validator.removeClass('d-none')
    $validator.addClass('show')
  });

  $node.on('focusout', () => {
    $validator.addClass('d-none')
    $validator.removeClass('show')
  });

  $node.on('keyup', (e) => {
    validatePassword($validator, $(e.target).val());
  });
}

const validatePassword = ($validator, password) => {
  const rules = {
    minlength: /.{8,}/,
    lowercase: /.*[a-z]/,
    uppercase: /.*[A-Z]/,
    special: /.*[!@#$%^&*]/,
    number: /\d/
  }

  for (const rule in rules) {
    var $node = $validator.find('.rule.' + rule + ' .status');
    if (rules[rule].test(password)) {
      $node.addClass('success')
    } else {
      $node.removeClass('success')
    }
  }
}

jQuery(document).on('turbolinks:load', () => {
  $('input.password').each((_i, node) => passwordToggler(node));
  $('input.password').each((_i, node) => passwordValidator(node));
});
