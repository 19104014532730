import Rails from '@rails/ujs'

const AUTOSAVE_FORM_SELECTOR = 'form[data-autosave-form][data-remote]';
const FORM_SELECTOR = 'form[data-remote-form]';
const ERROR_ATTR = 'data-remote-error';
const ERROR_SELECTOR = `div[${ERROR_ATTR}]`;
const SHOW_CLASS = 'd-block';
const ERROR_CLASS = 'invalid-feedback';

const resetWrapper = wrapper => {
  wrapper.querySelectorAll(`.${ERROR_CLASS}`).forEach(errorMessage => {
    wrapper.removeChild(errorMessage);
  });
  let input = wrapper.querySelector('.form-control');
  if (input) {
    input.classList.remove('is-invalid');
  }
}

Rails.delegate(document, FORM_SELECTOR, 'change', e => {
  e.target.classList.remove('is-valid', 'is-invalid');
  const submit = document.getElementsByName('commit')[0];
  if (submit) {
    submit.classList.remove('disabled');
  }
});

Rails.delegate(document, FORM_SELECTOR, 'ajax:before', e => {
  e.target.querySelectorAll(ERROR_SELECTOR).forEach(resetWrapper);
});

Rails.delegate(document, FORM_SELECTOR, 'ajax:error', e => {
  let errors = e.detail && e.detail[0] && e.detail[0].errors;
  if (!errors) return;
  Object.entries(errors).forEach(([attr, messages]) => {
    let wrapper = e.target.querySelector(`div[${ERROR_ATTR}="${attr}"]`);
    if (wrapper === null) return;

    let input = wrapper.querySelector('.form-control');
    if (input) {
      input.classList.add('is-invalid');
    }
    messages.forEach(message => {
      let errorMessage = document.createElement('div');
      errorMessage.innerHTML = message;
      errorMessage.classList.add(ERROR_CLASS, SHOW_CLASS);
      wrapper.appendChild(errorMessage);
    });
  });
});

Rails.delegate(document, FORM_SELECTOR, 'ajax:success', e => {
  if (e.target.$activeInput) {
    e.target.$activeInput.classList.add('is-valid');
  }
});

Rails.delegate(document, AUTOSAVE_FORM_SELECTOR, 'change', function(e) {
  if (e.target.value === '' && e.target.dataset.hasOwnProperty('skipBlank')) {
    resetWrapper(e.target.parentElement);
    return;
  }
  this.$activeInput = e.target;
  Rails.fire(this, 'submit');
});
