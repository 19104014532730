document.addEventListener('turbolinks:load', function() {
  const toggler = document.querySelector('.fun-fact-toggler');
  const bird = document.querySelector('.fun-fact-toggler .bird')
  const content = document.querySelector('.fun-fact-content');

  toggler?.addEventListener('click', function() {
    if(!this.classList.contains('open')) {
      this.classList.add('open');
      this.nextElementSibling.classList.add('open')
      $(content).find('audio')[0].play();
    }
  });

  bird?.addEventListener('mouseover', function() {
    toggler?.classList.add('hover')
  })

  bird?.addEventListener('mouseout', function() {
    toggler?.classList.remove('hover')
  })

  const close = document.querySelector('.fun-fact-close');

  close?.addEventListener('click', function() {
    content.classList.remove('open')
    toggler.classList.remove('open')
  });


  $(document).click(function(e) {
    if (!$(e.target).closest('.fun-fact').length) {
      if(content?.classList.contains('open')) {
        close?.click();
      }
    }
  });
});
