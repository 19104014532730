import $ from 'jquery'

jQuery(document).on('turbolinks:load', () => {
  $(`.collab-items`).css('display','none')
  $(`.collab-title[data-section-id='when_issue_arises']`).addClass('collab-focus')
  $(`.collab-items[data-section-id='when_issue_arises']`).css('display','block')

  $('.collab-transition').on('mouseover', function () {
    let section_id = $(this).data('section-id')
    $(`.collab-title`).removeClass('collab-focus')
    $(`.collab-items`).css('display','none')
    $(`.collab-title[data-section-id='${section_id}']`).addClass('collab-focus')
    $(`.collab-items[data-section-id='${section_id}']`).css('display','block')
  });

  $(`.secondary-nav .secondary-nav__main-collapse`).click(function (){
    if ($(`.manifesto-collapse`).hasClass(`collapse-padding`)) {
      $(`.manifesto-collapse`).removeClass('collapse-padding');
    } else {
      $(`.manifesto-collapse`).addClass('collapse-padding');
    }
    /*
    *  Using the window resize hack to fix the issue chart width when the side nav is collapsed.
    *  the setTimeout is to ensure the parent div is expanded before the width of the chart is set
    */
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'));
    }, 5);
  });

  $(".manifesto-link").on('click', function(e) {
    e.preventDefault()
    $('html, body').animate({
        scrollTop: $($(this).attr("href")).offset().top
    }, 600);
  });
});
