import Rails from '@rails/ujs'
import $ from 'jquery'
import setupInlinePlugins from './inline-plugins'

$(document).on('click', '[data-toggle=remote-modal]', e => {
  e.preventDefault();

  let $link = $(e.currentTarget);
  if ($link.data('rmblocked')) return;
  $link.data('rmblocked', true);

  let url = $link.data('url') || $link.attr('href');
  if (!url) {
    console.error('URL is not provided');
    return;
  }

  Rails.ajax({
    url,
    type: 'GET',
    success(_target, _, xhr) {
      if (xhr.status !== 200 && xhr.status !== 304) return;

      let $modal = $(xhr.responseText);
      $(document.body).append($modal)
      $modal.on('shown.bs.modal', () => setupInlinePlugins($modal));
      $modal.modal();
      $modal.on('hide.bs.modal', () => $modal.remove());
    },
    complete() {
      $link.data('rmblocked', false);
    }
  })
});

$(document).on('click', '[data-dismiss="modal"]', e => {
  let $link = $(e.currentTarget).data('href');

  if ($link) {
    window.location.href = $link;
  }
});

jQuery(document).on('turbolinks:load', () => {
  $.each($("a[data-toggle='remote-modal']"), function(i, val) {
    $(val).data("url", $(val).attr("href")).attr("href", "javascript:void(0);")
  });

  $.rails.href = function(el) {
    var $el = $(el);
    return $el.data('url') || $el.data('href') || $el.attr('href');
  }
});
