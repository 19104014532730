import Rails from '@rails/ujs'
import $ from 'jquery'

const updateChart = (url, chart, infoContainer) => {
  Rails.ajax({
    url: url,
    type: 'GET',
    success(_target, _, xhr) {
      if (xhr.status !== 200 && xhr.status !== 304) return;

      const graph = JSON.parse(xhr.responseText);
      const data = graph.data;
      const progression = graph.progression;

      // Remove all exisitng tooltips otherwise they stay in DOM
      document.querySelectorAll('.tooltip.graph').forEach((tooltip) => tooltip.remove());
      document.querySelectorAll(`g.ct-series.ct-series-${graph.theme || 'base'} > line.ct-fake-point`).forEach((fdp) => fdp.remove());

      chart.__chartist__.update(data, {classNames: { series: `ct-series ct-series-${graph.theme || 'base'}` }}, true);

      if (!graph.real_data) return

      infoContainer.querySelector(".percentage span").innerText = progression.last_percentage;
      infoContainer.querySelector(".progression-value").innerText = progression.last_change;
      infoContainer.querySelector(".progression-value-cagr").innerText = progression.compounded_annual_growth_rate.toFixed(2);
      infoContainer.querySelector(".progression").classList.remove("increase", "decrease", "zero");
      infoContainer.querySelector(".progression").classList.add(progression.last_change === 0 ? "zero" : progression.last_type);
    }
  })
}

jQuery(document).on('turbolinks:load', () => {
  document.querySelectorAll('.chart-container')?.forEach((el) => {
    el.addEventListener('click', (ev) => {
      const selectedArea = jQuery(ev.target).parents('.filter-container').find('.filter-selected');
      const filterScroll = jQuery(ev.target).parents('.filter-container');

      if (ev.target.tagName === 'LI') {

        ev.target.closest('.filter').querySelector('li.active')?.classList?.remove('active');
        ev.target.classList.add('active');

        if (filterScroll.hasClass('open')) {
          const force_real_data = document.getElementsByClassName('empty-chart-body');
          let url = ev.target.dataset.url
          url = url + `${url.split('?')[1] ? '&':'?'}force_real_data=${!force_real_data.length > 0}`;
          const chart = ev.target.closest('.chart-container').querySelector('[data-line-chart]');
          const infoContainer = ev.target.closest('.chart-container').querySelector('.info')
          const infoLink = ev.target.closest('.filter-dropdown').querySelector('.info-link');

          selectedArea.html(ev.target.innerText);

          if(ev.target.dataset.conduct !== undefined) {
            infoLink.style.display = 'block';
            $(infoLink).find('a').data('url', '/modals/conducts/' + ev.target.dataset.conduct);
          } else {
            infoLink.style.display = 'none';
          }
          updateChart(url, chart, infoContainer)
        }
      }

      if (filterScroll.hasClass('open')) {
        filterScroll.removeClass('open');
        $('body').removeClass('expanded');
      } else {
        filterScroll.addClass('open');
        $('body').addClass('expanded');
      }
    });

    $('body').click(function(ev) {
      if(!$(ev.target).closest('.filter-container').length) {
        $('.filter-container').removeClass('open');
        $('body').removeClass('expanded');
      }
    });

  });
});
