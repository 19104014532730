import 'g-bootstrap-select'
import 'bootstrap-datepicker'

import $, { type } from 'jquery'

import { Line, Svg, Interpolation } from 'chartist'
import Chart from 'chart.js/auto';

const animationDuration = 200;

const slider = node => {
  let $slider = $(node);
  $slider.on('click', '[data-to-slide]', e => {
    let $link = $(e.currentTarget);
    $link.parents('[data-slide]').addClass('d-none').removeClass('d-block');
    $slider.find(`[data-slide=${$link.data('toSlide')}]`).addClass('d-block').removeClass('d-none');
  });
}

const drawFakePoint = (e, config) => {
  let newLine = document.createElementNS('http://www.w3.org/2000/svg','line');
  const id = `ct-fake-point-${Math.round(e.x)}-${Math.round(e.y)}`;
  const existing = document.querySelectorAll(`g.ct-series-${config.theme || 'base'} > line#${id}`)[0]

  if (existing) {
    return;
  }

  newLine.setAttribute('id', id);
  newLine.setAttribute('x1',e.x - 10);
  newLine.setAttribute('y1',e.y - 10);
  newLine.setAttribute('x2',e.x + 10);
  newLine.setAttribute('y2',e.y + 10);
  newLine.setAttribute("stroke-width", 100)
  newLine.setAttribute("stroke-color", 'black')
  newLine.classList.add('ct-fake-point')
  $(e.element.getNode().closest('.ct-series')).append(newLine);

  return id;
}

const lineChart = node => {
  let config = JSON.parse(node.getAttribute('data-line-chart'));
  const options = {
    low: 0,
    high: 100,
    height: window.innerWidth > 767 ? 300 : 190,
    fullWidth: true,
    width: $('div[data-line-chart]').width(),
    axisY: {
      scaleMinSpace: 25,
      onlyInteger: true,
    },
    axisX: {
      labelOffset: {
        x: -15,
        y: 5
      },
    },
    chartPadding: {
      top: 15, right: 22,
      bottom: 0, left: 0
    },
    lineSmooth: Interpolation.monotoneCubic({
      fillHoles: true,
    }),
    classNames: { series: `ct-series ct-series-${config.theme || 'base'}` },
    showArea: true
  };

  let chart = new Line(node, config.data, options);

  chart.on('draw', e => {
    if (e.type === 'point') {
      if (e.meta === 'postpone') {
        e.element.getNode().remove();
      } else {
        const fakePointId = drawFakePoint(e, config);
        const fakePoint = e.element.getNode().closest('svg').querySelector(`#${fakePointId}`)

        $(fakePoint).tooltip({
          html: true,
          title: `<span class="value">${e.meta}</span> (<span class="change">${e.series[e.index]['change']}%</span>)`,
          delay: { start: 500, hide: 500 },
          offset: '0 6px',
          trigger: 'manual',
          customClass: `graph ${config.theme} ${e.series[e.index]['progression_type']}`
        })

        $(fakePoint).on('click', (ev) => {
          const point = $(ev.currentTarget);
          point.tooltip('show');
          setTimeout(() => {
            point.tooltip('hide')
          }, 1000);
        });

        $(fakePoint).on('mouseover', (ev) => {
          const point = $(ev.currentTarget);
          point.tooltip('show');
        });

        $(fakePoint).on('mouseleave', (ev) => {
          const point = $(ev.currentTarget);
          point.tooltip('hide');
        });
      }
    }
  });

  $(window).on('resize', function(){
    options.width = $('div[data-line-chart]').width();
    chart.update(this.data, options);
  });
}

const barChart = node => {
  let data = JSON.parse(node.getAttribute('data-bar-chart'));
  const theme = node.getAttribute('data-theme');

  const setUpConfig = {
    lineBorderWidth: 0,
    barThickness: 20,
    barBorderRadius: 4,
    layoutPaddingTop: 45,
    layoutPaddingLeft: 50,
    xAxisFont: {
      size: 12,
      weight: 400,
      family: "Inter, sans-serif",
      lineHeight: '18px'
    },
    xAxisPadding: 22,
    xAxisTextLength: 20,
    tooltipBorderWidth: 0.5,
    tooltipBodyFont: {
      size: 16,
      weight: 600,
      fontFamily: "Inter, sans-serif"
    },
    stepSize: 25,
    pointFillColor: "#FFD59B",
    pointStroke: "#FDFFFF",
    pointFillColorHover: "#FF9505",
    pointImageHeight: 2,
    fontFamily: "Inter, sans-serif",
    secondaryLight: "#9DA2A4",
    semanticOrangeBg: "#FFF4E6",
    secondaryDark: "#3A3D3F",
    stroke: "#F1F2F2",
    label: "#737A7D",
    fillHover: "rgba(215, 216, 228, 0.2)",
    neutralBaseWhite: "#FDFFFF",
    ['positive']: {
      tooltipBackground: "#EBF8FB",
      barBg: "#A1E0EE",
      barBgHover: "#78D3E6",
    },
    ['negative']: {
      tooltipBackground: "#FDE6E6",
      barBg: "#FDE6E6",
      barBgHover: "#F99999",
    },
    ['semantic_orange_positive']: {
      tooltipBackground: "#EBF8FB",
      barBg: "#FF9505",
      barBgHover: "#78D3E6",
    },
    ['semantic_orange_negative']: {
      tooltipBackground: "#FDE6E6",
      barBg: "#FF9505",
      barBgHover: "#F99999",
    }
  }

  const point = (isHover) => {
    const {pointFillColor, pointStroke, pointFillColorHover} = setUpConfig;

    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgElement.setAttribute('width', '23');
    svgElement.setAttribute('height', '8');
    svgElement.setAttribute('viewBox', '0 0 23 8');
    svgElement.innerHTML = `<rect x="1.39844" y="0.5" width="20" height="7" rx="3.5" fill="${isHover ? pointFillColorHover : pointFillColor}" stroke="${pointStroke}"/>`;
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const image = new Image();
    image.src = 'data:image/svg+xml;base64,' + btoa(svgString);
    return image;
  }

  data.datasets.forEach((dataset, index) => {
    if (index === 0 && data.datasets.length > 1) {
      dataset.type = "line";
      dataset.borderWidth = setUpConfig.lineBorderWidth;
      dataset.fill = false;
      dataset.pointBorderColor = setUpConfig.neutralBaseWhite;
      dataset.pointHitRadius = 5;
      dataset.pointStyle = (context) => {
        return context.parsed.y !== null ? (context.mode === "active" ? point(true) : point(false)) : undefined;
      };
      dataset.data = dataset.data.map((item) => {
        if(item !== null) {
          return item > 98 ? item - setUpConfig.pointImageHeight : item < 2 ? item + setUpConfig.pointImageHeight : item
        }
        return item
      });
    } else {
      dataset.type = "bar";
      dataset.backgroundColor = setUpConfig[theme].barBg;
      dataset.barThickness = setUpConfig.barThickness;
      dataset.borderSkipped = false;
      dataset.barPercentage = 0.4;
      dataset.hoverBackgroundColor = setUpConfig[theme].barBgHover;
      dataset.borderRadius = {
        topLeft: setUpConfig.barBorderRadius,
        topRight: setUpConfig.barBorderRadius,
        bottomRight: setUpConfig.barBorderRadius,
        bottomLeft: setUpConfig.barBorderRadius,
      };
    }
  });

  function truncateLabel(value) {
    if (value.length > setUpConfig.xAxisTextLength) {
      return value.slice(0, setUpConfig.xAxisTextLength) + '...';
    } else {
      return value;
    }
  }

  const options = {
    maintainAspectRatio: false,
    onHover: (e, chartElement) => {
      if (chartElement.length > 0) {
        e.native.target.style.cursor = 'pointer'
      } else {
        e.native.target.style.cursor = 'default'
      }
    },
    layout: {
      padding: {
        top: setUpConfig.layoutPaddingTop,
        bottom: 0,
        left: setUpConfig.layoutPaddingLeft
      }
    },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: setUpConfig.stepSize,
          font: {
            size: 12,
            weight: 600
          },
          callback: () => {
            return "";
          },
        },
        grid: {
          color: setUpConfig.stroke
        },
        border: {
          width: 0
        }
      },
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          width: 0
        },
        ticks: {
          color: setUpConfig.label,
          font: setUpConfig.xAxisFont,
          padding: setUpConfig.xAxisPadding,
          callback: function(val) {
            const label = truncateLabel(this.getLabelForValue(val)).split(" ");
            return label.length > 2 ? [label.slice(0, 2).join(' '), label.slice(2).join(' ')] : label;
          },
          maxRotation: 0,
          autoSkip: false
        },
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          const {chart, tooltip} = context;
          const BgColor = tooltip.dataPoints[0].dataset.type === 'bar' ? setUpConfig[theme].tooltipBackground : setUpConfig.semanticOrangeBg;

          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';;
            document.body.appendChild(tooltipEl);
          }
          tooltipEl.style.backgroundColor = BgColor;

          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          const getBody = (bodyItem) => {
            return bodyItem.lines;
          }

          if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(getBody);

            let innerHtml = `<div class="arrow-down" style="border-bottom: 8px solid ${BgColor};"></div>`;
            innerHtml += `<div class="arrow-down-border"></div>`;
            bodyLines.forEach((body) => {
              let tooltipText = Number(body);
              if (tooltip.dataPoints[0].datasetIndex === 0) {
                tooltipText = tooltipText === 98 ? tooltipText + setUpConfig.pointImageHeight : tooltipText === 2 ? tooltipText - setUpConfig.pointImageHeight : tooltipText
              }
              innerHtml += `<b>${tooltipText}%</b> - `;
            });

            titleLines.forEach((title) => {
              innerHtml += `<span>${title}</span>`;
            });

            tooltipEl.innerHTML = innerHtml;
          }
          const borderWidth = 2;
          const arrowHeight = 13;
          const tooltipWidth = (tooltipEl.clientWidth + borderWidth) / 2;
          const tooltipHeight = (tooltipEl.clientHeight + arrowHeight);

          const position = chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = position.left + window.pageXOffset - tooltipWidth + tooltip.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset - tooltipHeight + tooltip.caretY + 'px';
          tooltipEl.style.padding = tooltip.padding + 'px ' + tooltip.padding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      }
    }
  };

  const imagePositions = [];
  let isInitialDraw = true;
  const customization = {
    id: "customization",
    beforeDatasetsDraw: chart => {
      const {ctx, tooltip, chartArea: {top, bottom, height}, scales: {x, y}} = chart;

      // Emoji icons setup
      y._gridLineItems.forEach((item, index) => {
        const id = "#" + theme + "-" + index
        const img = document.querySelector(id);
        const elementTop = item.ty1;

        img && ctx.drawImage(img, 0, elementTop - 28, 21, 23);

        ctx.font = setUpConfig.fontFamily;
        ctx.fillStyle= setUpConfig.secondaryLight;
        ctx.fillText(index * setUpConfig.stepSize + "%", 28, elementTop - 14);

        ctx.fillStyle = setUpConfig.stroke
        ctx.beginPath();
        const xAxis = 10;
        const yAxis = elementTop - 0.5;
        const width = 50;
        const height = 1;
        ctx.rect(xAxis, yAxis, width, height);
        ctx.fill();

        ctx.beginPath();
        ctx.arc(10, elementTop, 3, 0, 2 * Math.PI);
        ctx.fill();
      });

      // information buttons
      x.ticks.forEach((tick, index) => {
        const id = "#information-line"
        const img = document.querySelector(id);
        const tickPosition = x.getPixelForTick(index);
        const imgYPos = bottom + 65;
        img && ctx.drawImage(img, tickPosition - (img.width / 2), imgYPos);

        if(img) {
          imagePositions.push({
            x: tickPosition - (img.width / 2),
            y: imgYPos,
            width: img.width,
            height: img.height,
            imageId: data.ids[index],
          });
        }

      });


      // background hover color and path extender with round circle points
      if(tooltip._active[0]) {
        const startAt = x._gridLineItems[1]?.x1;
        const endAt = x._gridLineItems[2]?.x1;
        let width = startAt - endAt;
        const index = tooltip._active[0]?.index;

        let startPoint = x._gridLineItems[index + 1]?.x1;
        let newTop = top - 38;
        let newHeight = height + 127;
        let radius = 10;

        ctx.fillStyle = setUpConfig.fillHover;
        ctx.beginPath();
        if (typeof CanvasRenderingContext2D.prototype.roundRect === 'function') {
          ctx.roundRect(startPoint, newTop, width, newHeight, radius);
        } else {
          ctx.rect(startPoint, newTop, width, newHeight, radius);
        }
        ctx.closePath();
        ctx.fill();
      }
    },
    afterUpdate: chart => {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item, index) => {
        const checkbox = document.getElementById(`check-${theme}-${index+1}`);

        if (checkbox) {
          checkbox.onchange = () => {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            chart.update();
          };
        }
      });
    },

    afterDraw: chart => {
      if (isInitialDraw) {
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        items.forEach((item, index) => {
          const checkbox = document.getElementById(`check-${theme}-${index+1}`);
          if(checkbox) {
            setTimeout(function() {
              if (!checkbox.checked) {
                chart.setDatasetVisibility(item.datasetIndex, false);
                chart.update();
              }
            }, 200)
          }
        })
        isInitialDraw = false;
      }
    },
  };

  ['mousemove', 'click'].forEach((item) => {
    if(item) {
      node.addEventListener(item, function(event) {
        const rect = node.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        for (let imgPos of imagePositions) {
          if (x > imgPos.x && x < imgPos.x + imgPos.width && y > imgPos.y && y < imgPos.y + imgPos.height) {
            node.style.cursor = 'pointer'
            if(item === 'click') {
              const link = document.getElementById('chart-info-link');
              $(link).data('url', '/modals/conducts/' + imgPos.imageId);
              link.click()
            }
            return;
          } else {
            node.style.cursor = 'default'
          }
        }
      });
    }
  });


  new Chart(node.getAttribute('id'), {
    type: 'bar',
    options: options,
    data,
    plugins: [customization]
  });

  const chartBoxes = document.getElementsByClassName('chart-box');

  const showHideTheChartScrollInfo = () => {
    setTimeout(() => {
      const chartBox = chartBoxes[0];
      let chartBody = chartBox.parentElement;
      let scrollElement = chartBody.parentElement.getElementsByClassName('chart-footer')[0];
      let scrollWidth = chartBody.scrollWidth;
      let clientWidth = chartBody.clientWidth;
      if (scrollWidth > clientWidth) {
        scrollElement.style.display = 'block';
      } else {
        scrollElement.style.display = 'none';
      }
    }, 500)
  }

  window.addEventListener('resize', function() {
    showHideTheChartScrollInfo();
  });
  showHideTheChartScrollInfo();
}

const onAnchorLinkClick = e => {
  e.preventDefault();
  e.stopPropagation();
  let $link = $(e.currentTarget);
  let target = document.querySelector($link.data('target') || $link.attr('href'));
  target.scrollIntoView({ behavior: 'smooth', inline: 'center' })
}

Object.assign($.fn.tooltip.Constructor.Default, {
  delay: { start: 500, hide: 500 },
  boundary: 'viewport'
});

const onViewToggle = e => {
  e.preventDefault();
  let $this = $(e.currentTarget);
  let className = $this.data('class') || 'show';
  let targetSelector = $this.data('target') || $this.attr('href');
  $(targetSelector).toggleClass(className);
}

const onSidebarContentClick = e => {
  if (!$(e.target).hasClass('dont-stop-prop')) {
    e.stopPropagation();
  }
}

const onInvalidChange = e => {
  $(e.target).removeClass('is-invalid');
}

const onCheckboxClick = e => {
  $(e.target).toggleClass('checked');
}

const onNotApplicableClick = e => {
  let $target = $(e.target);
  let $radio = $('#survey_form_responses_' + $target.data('conductId') + '_0');

  if ($target.hasClass('checked')) {
    $radio.prop('checked', true);
    $radio.parents('.feedback-box__question').find('.checkbox.not_applicable').addClass('checked');
  } else {
    $radio.prop('checked', false);
    $('.feedback-submit').addClass('disabled');
    $radio.parents('.feedback-box__question').find('.checkbox.not_applicable').removeClass('checked');
  }

  $radio.parents('.feedback-box__question').find('.feedback-box__buttons .feedback-box__button').each((_, node) => {
    if ($radio.prop('checked')) {
      $(node).addClass('disabled').removeClass('active');
    } else {
      $(node).removeClass('disabled').prop('checked', false);
    }
  });

  let $title = $radio.parents('.feedback-box__question').find('.feedback-box__question-title');
  $title.toggleClass('disabled');
}

const initDatepickers = $container => {
  $container.find('.datepicker').each((o, val) => {
    const $picker = $(val);
    const options = {
      format: $picker.data('format'),
      startDate: $picker.data('start-date')
    };

    $picker.datepicker(options).on('changeDate', () => {
      $container.find('.btn-primary.disabled').removeClass('disabled');
    }).on('hide', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
  })
}

const showSideNavbar = e => {
  $('.side-navbar').addClass('show');
}
const hideSideNavbar = e => {
  $('.side-navbar').removeClass('show');
}

const individual_tracker_create = e => {
  const modalBody = $(e.target).parents('.modal-body')
  setTimeout(function() {
    modalBody.find('#copy_to_clipboard').find('.btn').click(function() {
      console.log('here', modalBody)

      const clipboard = $(this).parents('#copy_to_clipboard');
      clipboard.find('.tracker-modal-card').hide()
      clipboard.find('.alert').removeClass('d-none');
      clipboard.find('button.close').click(function() {
        clipboard.find('.tracker-modal-card').show();
        clipboard.find('.alert').addClass('d-none');
      })
    })
  }, 1000)
}

export default $container => {
  initDatepickers($container);
  $container.find('.checkbox').on('click', onCheckboxClick);
  $container.find('.not_applicable').on('click', onNotApplicableClick);
  $container.find('[data-toggle="tooltip"]').tooltip();
  $container.find('[data-toggle="collapse"]').collapse({ toggle: false });
  $container.find('select[multiple]').selectpicker({ actionsBox: true, liveSearch: true });
  $container.find('.modal-backdrop.fade.show').remove();
  $container.find('.modal.show').modal();
  $container.find('[data-modal-autoopen]').modal('show');
  $container.find('[data-slider]').each((_i, node) => slider(node));
  $container.find('[data-line-chart]').each((_i, node) => lineChart(node));
  $container.find('[data-bar-chart]').each((_i, node) => barChart(node));
  $container.on('click', '[data-scroll]', onAnchorLinkClick);
  $container.on('click', '[data-toggle=view]', onViewToggle);
  $container.on('click', '.sidebar .sidebar__content', onSidebarContentClick);
  $container.on('click', '.nav-toggle', showSideNavbar);
  $container.on('click', '.close-side-nav', hideSideNavbar);
  $container.find('.is-invalid').on('change', onInvalidChange);
  $container.on('click', '.button_create', individual_tracker_create)
}
