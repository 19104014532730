let ITEM_PER_PAGE = 10;

function manageLoadMoreBtn() {
  const loadMoreTable = document.querySelectorAll('.load-more-table');
  for (let i = 0; i < loadMoreTable.length; i++) {
    const hiddenItemsRemaining = loadMoreTable[i].querySelectorAll('.hidden');

    if (hiddenItemsRemaining.length === 0) {
      loadMoreBtnShowHide(loadMoreTable[i], false);
    } else {
      loadMoreBtnShowHide(loadMoreTable[i], true);
    }
  }

}

function loadMoreBtnShowHide(loadMoreTable, show) {
  const loadMoreBtn = loadMoreTable.querySelector('.load-more-btn');
  if(show) {
    loadMoreBtn.classList.remove('d-none');
  } else {
    loadMoreBtn.classList.add('d-none');
  }
}

window.loadMore = function (e = null, ipp = null) {
  let loadMoreTable = document.querySelectorAll('.load-more-table')
  if (e !== null) {
    loadMoreTable = $(e.target).parents('.load-more-table')
  }
  ITEM_PER_PAGE = ipp ? ipp : 10;
  for (let i = 0; i < loadMoreTable.length; i++) {
    const hiddenItems = loadMoreTable[i].querySelectorAll('.hidden');

    if (hiddenItems) {
      for (let j = 0; j < ITEM_PER_PAGE; j++) {
        const item = hiddenItems[j];
        if (item) {
          item.classList.remove('hidden');
        } else {
          manageLoadMoreBtn();
          break;
        }
      }
      manageLoadMoreBtn();
    }
  }
}

$(document).on('click', '.load-more-btn', function(e) {
  loadMore(e, $(this).data('ipp'))
});

jQuery(document).on('turbolinks:load', () => {
  const loadButton = $('.load-more-table .load-more-btn');
  if (loadButton.length > 0) {
    loadMore(null, loadButton.data('ipp'));
  }
  manageLoadMoreBtn();
});