import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      handle: '.handle'
    })
  }

  end(event) {
    let type = event.item.dataset.type
    let id = event.item.dataset.id
    let data = new FormData()

    data.append('position', event.newIndex + 1)
    Rails.ajax({
      url: this.data.get('url').replace(':type', type).replace(':id', id),
      type: 'PATCH',
      data: data
    });
  }
}
