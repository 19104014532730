import Flickity from 'flickity'

jQuery(document).on('turbolinks:load', () => {
  var elem = document.querySelector('.main-carousel');
  if (!elem) return;

  var flkty = new Flickity(elem, {
    freeScroll: false,
    wrapAround: true,
    prevNextButtons: false
  });

  var elem = document.querySelector('.main-lg-carousel');
  if (!elem) return;

  var flkty = new Flickity(elem, {
    freeScroll: false,
    contain: true,
    wrapAround: true,
    pageDots: false,
    arrowShape: 'M 27 29 L 24 26 L -6 50 L 24 73 L 27 70 L 5 52 H 113 V 47 H 5 Z'
  });
});
