jQuery(document).on('turbolinks:load', () => {
  const commentResponseTextAreaTemplate = `<div class="comment-response ##type##">##icon##<textarea rows="1" name="##name##" class="last-focused"></textarea></div>`;
  const commentsTextArea = $('.comments-textarea');
  commentsTextArea.each((index, textarea) => {
    const commentsTextAreaBody = $(textarea).find('.comments-textarea-body');
    const commentsTextAreaPlaceholder = commentsTextAreaBody.find('.placeholder');

    commentsTextAreaPlaceholder.on('click', function () {
      appendTextArea($(textarea).data('type'), commentsTextAreaBody);
      commentsTextAreaBody.find('textarea').focus();
      activateCommentResponseRemove(commentsTextAreaBody);
      loadTextareaExpanding(commentsTextAreaBody);
    });
  });

  const ICONS = {
    insight: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="svg-icon quill-pen-line insight"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z"></path><path d="M4.627 9.357c-.156.416-.287.8-.404 1.189.64-.465 1.4-.76 2.278-.87 1.676-.209 3.164-1.315 3.918-2.705l-.971-.97.942-.943.667-.667c.286-.287.61-.816.952-1.579-3.729.578-6.012 2.861-7.383 6.545zm6.706-3.356.667.666c-.667 2-2.667 4-5.333 4.333-1.78.223-2.891 1.445-3.335 3.667H2c.667-4 2-13.334 12-13.334-.667 1.998-1.332 3.331-1.998 3.998l-.669.67z" fill="#293032"></path></g></svg>`,
    action: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon checkbox-circle-line action"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-1.562-4.5L6.5 11.75l1.312-1.25 2.626 2.5 5.249-5L17 9.25l-6.562 6.25z" id="Shape" fill="#293032"></path></g></svg>`,
  };

  const activateCommentResponseRemove = (commentsTextAreaBody) => {
    commentsTextAreaBody.on('keydown', 'textarea', function(e) {
      const $this = $(this);
      const isEmpty = $this.val() === '';
      const isBackspace = e.keyCode === 8;
      const isDelete = e.keyCode === 46;

      if ((isBackspace || isDelete) && isEmpty) {
        const commentResponse = $this.parents('.comment-response');
        const prevCommentResponse = commentResponse.prev('.comment-response');
        const prevCommentResponseTextArea = prevCommentResponse.find('textarea');
        const allCommentsTextArea = $this.parents('form').find('textarea');

        allCommentsTextArea.removeClass('last-focused');
        prevCommentResponseTextArea.focus().addClass('last-focused');
        commentResponse.remove();

        const allCommentsResponse = commentsTextAreaBody.find('.comment-response');
        e.preventDefault();

        if (allCommentsResponse.length === 0) {
          commentsTextAreaBody.find('.placeholder').show();
        }
      }
    });
  };


  const loadTextareaExpanding = (commentsTextAreaBody) => {
    commentsTextAreaBody
      .on('input', 'textarea', function() {
        this.style.height = 'auto';
        this.style.height = `${this.scrollHeight}px`;
      })
      .find('textarea')
      .each(function() {
        this.style.height = `${this.scrollHeight}px`;
      });
  };

  const updateCommentsTextarea = (type, enterKey = false, textareaBody) => {
    const $lastFocused = textareaBody.find('.focused');
    const $lastFocusedResponse = $lastFocused.parents('.comment-response');
    const $lastFocusedTextarea = $lastFocusedResponse.find('textarea');

    if (enterKey && $lastFocusedTextarea.val()) {
      appendTextArea(type, textareaBody);
      $lastFocusedResponse.next('.comment-response').find('textarea').focus();
    } else if ($lastFocused.length) {
      $lastFocusedResponse
        .removeClass()
        .addClass(`comment-response ${type}`)
        .find('svg')
        .remove()
        .end()
        .prepend(ICONS[type]);
      $lastFocusedTextarea.attr('name', `feedback_form[${type}][]`);
      $lastFocusedTextarea.focus();
    } else {
      appendTextArea(type, textareaBody);
      textareaBody.find('textarea').focus();
    }

    loadTextareaExpanding(textareaBody);
    activateCommentResponseRemove(textareaBody);
  };

  const appendTextArea = (type, commentsTextAreaBody) => {
    const textAreaTemplate = commentResponseTextAreaTemplate.replace(/##name##/g, `feedback_form[${type}][]`).replace(/##icon##/g, ICONS[type]).replace(/##type##/g, type);
    const lastFocused = commentsTextAreaBody.find('.focused');
    const $textarea = $(textAreaTemplate).find('textarea');

    $textarea
      .on('blur', () => {
        $textarea.removeClass('last-focused');
        $textarea.addClass('last-focused');
      })
      .on('focus', () => {
        $textarea.parents('form').find('textarea').removeClass('focused');
        $textarea.addClass('focused');
        $textarea.unbind('keydown');
        activateCommentResponseRemove(commentsTextAreaBody);
        $textarea.on('keydown', (e) => {
          if (e.keyCode == 13 && !e.shiftKey) {
            e.preventDefault();
            updateCommentsTextarea(type, true, commentsTextAreaBody);
            $textarea.parents('.comment-response').next('.comment-response').find('textarea').focus();
          }
        });
      });

    if (lastFocused.length > 0) {
      lastFocused.parents('.comment-response').after($textarea.parent());
    } else {
      commentsTextAreaBody.append($textarea.parent());
    }

    if ($textarea.length > 0) {
      commentsTextAreaBody.find('.placeholder').hide();
    }

    commentsTextAreaBody.find('.focused').focus();
  };



});
