import AdminController from './admin_controller';

export default class extends AdminController {
  static targets = AdminController.targets.concat(['cmsFields', 'dynamicFields'])

  connect() {
    super.connect();
    this.updateFormForKind('cms');
  }

  onKindChange(e) {
    this.updateFormForKind(e.target.value);
  }

  updateFormForKind(type) {
    if (type === 'cms') {
      this.dynamicFieldsTarget.classList.add('d-none');
      this.cmsFieldsTarget.classList.remove('d-none');
    } else if (type === 'dynamic') {
      this.cmsFieldsTarget.classList.add('d-none');
      this.dynamicFieldsTarget.classList.remove('d-none');
    }
  }
}
