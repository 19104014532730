jQuery(document).on('turbolinks:load', () => {
  setTimeout(() => {
    const hash = window.location.hash;
    hash && $(`.nav-tabs a[href="${hash}"]`).click();
    hideDropdown(hash);
  }, 200);

  $('.nav-tabs a').click(function () {
    window.location.href = this.hash;
    hideDropdown(this.hash);
  });


  const tabDefault = $('.tabs-default');
  const tabDefaultLink = tabDefault.find('a');
  const tabDefaultLinkActive = tabDefault.find('a.active');

  tabDefault.addClass(tabDefaultLinkActive.data('type'));
  tabDefaultLink.click(function() {
    tabDefaultLink.each(function() {
      tabDefault.removeClass($(this).data('type'));
    })
    $(this).parents('.tabs-default').addClass($(this).data('type'));
  });

  function hideDropdown(hash) {
    const dropdown = jQuery('.header_dropdown');
    const link = jQuery('.exit-link');

    if(hash === "#tracker_results") {
      dropdown.hide();
      link.show();
    } else {
      dropdown.show();
      link.hide();
    }
  }

  $('.learn .filters a').on('click', function() {
    $('.learn .filters a.active').removeClass('active');
    $(this).addClass('active');

    $('.learn .tab-content .tab-pane').removeClass('active');
    $($(this).attr('href')).addClass('active');
  });

});
