import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['role', 'formInput'];

  connect() {
    this.showRoleFormInput()
  }

  showRoleFormInput() {
    if (this.roleTarget.value == 1 || this.roleTarget.value == '') {
      this.formInputTarget.classList.add('d-none')
    } else {
      this.formInputTarget.classList.remove('d-none')
    }
  }
}
