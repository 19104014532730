// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
global.toastr = require("toastr")

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery;

// jQuery Ui widgets
import 'jquery-ui-dist/jquery-ui';

import 'bootstrap'
import 'stylesheets/app'

import 'services/clipboard'
import setupInlinePlugins from 'services/inline-plugins'
import 'services/remote-form'
import 'services/remote-modal'
import 'services/flickity'
import 'services/manifesto'
import 'services/hubble-dev'
import 'services/password-input'
import 'services/graph-filter'
import 'services/comments'
import 'services/load-more'
import 'services/tabs'
import 'services/dropdowns'
import 'services/dynamic-color'
import 'services/productive-discussion-textarea'
import 'services/fun-fact'
import 'services/table-drag-and-drop'

import trackGoogleAnalytics from 'services/analytics'

export const application = Application.start();
const context = require.context("../controllers", true, /.js$/);
application.load(definitionsFromContext(context));

jQuery(document).on('turbolinks:load', (event) => {
  setupInlinePlugins($('body'));
  let pageNavId = $('[data-scroll-nav]').attr('id');
  if (pageNavId) {
    $('body').scrollspy({ target: '#' + pageNavId });
  }

  trackGoogleAnalytics(event);

  // To smooth scroll the scrollspy pages without reloading the page
  $("body[data-spy='scroll'] a[href^='#']").on('click', function(e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: $(this.hash).offset().top
    });
  });

  document.querySelector(".modals_accept_terms")?.click()

  $(".btn-celebration").click(function() {
    const confetti = $('.confetti');
    const $this = this;
    $($this).css('pointer-events', 'none');
    confetti.parents('.card').css({position: 'relative'})
    confetti.css({position: 'absolute', bottom: '40px', left: '0', right: '0', margin: 'auto', width: '100px'})
    const url = confetti.attr('src');
    confetti.attr('src', '');
    confetti.attr('src', url);
    confetti.removeClass('d-none');
    setTimeout(() => {
      confetti.addClass('d-none')
      $($this).css('pointer-events', 'initial');
    }, 1680)
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
