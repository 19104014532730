import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['emailInput', 'passwordInput'];

  showPasswordInput() {
    if (this.emailInputTarget.value == this.data.get('savedEmail')) {
      this.passwordInputTarget.classList.add('d-none')
      this.passwordInputTarget.value = '';
    } else {
      this.passwordInputTarget.classList.remove('d-none')
    }
  }
}
