function reverseChildren(parent) {
  for (var i = 1; i < parent.childNodes.length; i++) {
    parent.insertBefore(parent.childNodes[i], parent.firstChild);
  }
}

function reverseSorting() {
  const allComments = document.querySelectorAll('.comment-item');
  allComments.forEach(comment => {
    comment.classList.add('hidden');
  });
  reverseChildren(document.querySelector('.comments-wrapper'));
  window.loadMore();
}

$(document).on('click', '#reverse_sorting', reverseSorting);